<div class="video-wrapper">
  <div class="video-content">
    <span class="name">{{ videoInfo.name }}</span>
    <span class="video">
      <iframe
        width="800"
        height="400"
        [src]="videoUrl"
        [name]="videoInfo.name"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
      ></iframe>
    </span>
  </div>
  <app-button 
    *ngIf="!isBackOffice && mcq?.length <= 0" 
    class="next-course-button" 
    [text]="isSelectedCourseLast ? 'Compléter le module' : 'Cours suivant'" 
    (onClick)="goToNextCourse.emit()">
  </app-button>
</div>