import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IButtonSize } from '../button/button.component';

export type callToActionTheme = 'default' | 'coaching';

@Component({
  selector: 'app-call-to-action-dialog',
  templateUrl: './call-to-action-dialog.component.html',
  styleUrls: ['./call-to-action-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CallToActionDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      text: string;
      theme: callToActionTheme;
      size: IButtonSize;
      actionText: string;
      actionUrl: string;
      actionExtUrl: string;
      hideLogin: boolean;
      redirectionAfterAction: string;
    },
    public dialogRef: MatDialogRef<CallToActionDialogComponent>,
    public router: Router
  ) {}

  ngOnInit(): void {}

  redirectToAction(): void {
    if (this.data.actionUrl) {
      const url = this.router.serializeUrl(this.router.createUrlTree([this.data.actionUrl]));
      window.open(url, '_blank');
      this.dialogRef.close();
    } else if (this.data.actionExtUrl) {
      window.open(this.data.actionExtUrl, '_blank');
    }
  }

  redirectToLogin(): void {
    this.router.navigate(['/profile/login'], { state: { redirectTo: this.data.redirectionAfterAction } });
    this.dialogRef.close();
  }
}
