import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IFormFieldCondition } from '../models/FormFieldCondition';
import { platforms } from './items-lists/platforms';
import { links } from './items-lists/links';
import { campaignTypes } from './items-lists/campaignTypes';
import { stepHeaders } from './items-lists/stepHeaders';
import { IFormFieldImage, ISelectableFormFieldImage } from '../models/SelectableFormFieldImage';
import { IInputFormField } from '../models/InputFormField';
import { IStepHeader } from '../models/StepHeader';
import { MatStepper } from '@angular/material/stepper';
import { CampaignType, ICampaign, ICampaignMetadata, IUser, Platform, UserRoleEnum } from '@shared';
import { IEstimation } from '../models/Estimation';
import { catchError, firstValueFrom, take, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SuccessService } from '../success/success.service';
import { SubSink } from 'subsink';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { CallToActionDialogComponent } from '../../shared/call-to-action-dialog/call-to-action-dialog.component';
import { FirebaseService } from 'src/app/firebase/firebase.service';

@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CampaignFormComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  platforms: ISelectableFormFieldImage[];
  links: IInputFormField[];
  types: IFormFieldImage[];
  stepHeaders: IStepHeader[];

  @ViewChild('stepper') stepper: MatStepper;

  estimation: IEstimation | null;

  loadingEstimation: boolean = false;

  private subs = new SubSink();

  demo = false;
  currentUser: IUser | null = null;

  constructor(
    private _formBuilder: FormBuilder,
    private successService: SuccessService,
    private firebaseService: FirebaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.demo = this.activatedRoute.snapshot.data.demo;
    this.platforms = platforms;
    this.links = links;
    this.types = campaignTypes;
    this.stepHeaders = stepHeaders;

    this.estimation = null;

    this.formGroup = this._formBuilder.group({
      formArray: this._formBuilder.array([
        // Step 1
        this._formBuilder.group({
          type: ['', Validators.required]
        }),
        // Step 2
        this._formBuilder.group({
          platform: ['', Validators.required]
        }),
        // Step 3
        this._formBuilder.group({
          youtubeUrl: [''],
          youtubeEmail: [''],
          facebookPage: [''],
          facebookPostUrl: [''],
          instaPostUrl: [''],
          projectUrl: [''],
          tiktokPostUrl: [''],
          eventUrl: [''],
          weTransferUrl: [''],
          weTransferUrlSpotify: [''],
          countries: ['', Validators.required]
        }),
        // Step 4
        this._formBuilder.group({
          budget: ['', Validators.required],
          comments: [''],
          email: [{ value: '', disabled: !this.demo }]
        })
      ])
    });

    if (!this.demo) {
      this.firebaseService.getCurrentUserProperties().subscribe((user) => {
        this.currentUser = user;
        const step3Form = this.formArray.get([2]);
        const step4Form = this.formArray.get([3]);

        step3Form.get('facebookPage').setValue(user.campaignInfo?.facebookUrl ?? '');
        step3Form.get('countries').setValue(user.campaignInfo?.defaultCountries ?? '');
        step4Form.get('email').setValue(user.email);
      });
    }
  }

  get formArray(): AbstractControl | null {
    return this.formGroup.get('formArray');
  }

  onTypeClick(newType: string) {
    this.formArray?.get([1, 'platform'])?.setValue('');
    this.changeFormValue(0, 'type', newType);
    this.goNextStep();
  }

  onPlatformClick(newPlatform: string) {
    this.formArray?.get([2])?.reset();
    this.formArray?.get([2, 'facebookPage'])?.clearValidators();
    this.formArray?.get([2, 'facebookPage'])?.updateValueAndValidity();
    this.formArray?.get([2, 'facebookPostUrl'])?.clearValidators();
    this.formArray?.get([2, 'facebookPostUrl'])?.updateValueAndValidity();
    this.formArray?.get([2, 'youtubeEmail'])?.clearValidators();
    this.formArray?.get([2, 'youtubeEmail'])?.updateValueAndValidity();
    this.formArray?.get([2, 'tiktokPostUrl'])?.clearValidators();
    this.formArray?.get([2, 'tiktokPostUrl'])?.updateValueAndValidity();
    if (this.currentUser) {
      this.formArray?.get([2, 'youtubeEmail']).setValue(this.currentUser.campaignInfo?.youtubeEmail);
      this.formArray?.get([2, 'facebookPage']).setValue(this.currentUser.campaignInfo?.facebookUrl);
      this.formArray?.get([2, 'countries']).setValue(this.currentUser.campaignInfo?.defaultCountries);
    }
    this.changeFormValue(1, 'platform', newPlatform);
    // faire une fonction qui get les links choisis selon les conditions précédentes et set le disabled de l'étape 3 dessus
    this.goNextStep();
  }

  onLinkValidationClicked() {}

  resetBudget() {
    this.changeFormValue(3, 'budget', '');
    this.estimation = null;
  }

  changeFormValue(group: number, selectedProperty: string, value: string) {
    this.formArray?.get([group, selectedProperty])?.setValue(value);
  }

  formValueChecker(conditions: IFormFieldCondition[]) {
    let result = true;
    conditions.forEach((condition) => {
      if (
        condition.validOptions.includes('*') &&
        this.formArray?.get([condition.formGroupId, condition.fieldName])?.value &&
        this.formArray?.get([condition.formGroupId, condition.fieldName])?.value !== ''
      ) {
        return;
      }
      if (!condition.validOptions.some((option) => option === this.formArray?.get([condition.formGroupId, condition.fieldName])?.value)) {
        result = false;
        return;
      }
    });
    return result;
  }

  estimate(budget: number) {
    if (budget == 0) {
      this.changeFormValue(3, 'budget', '');
      this.estimation = null;
      return;
    }
    this.loadingEstimation = true;
    this.changeFormValue(3, 'budget', budget.toString());
    this.estimation = this.calculateEstimation(budget);
    setTimeout(() => {
      this.loadingEstimation = false;
    }, 1500);
  }

  calculateEstimation(budget: number): IEstimation | null {
    if (budget < 10) {
      return null;
    }

    if (this.formArray?.get([1, 'platform'])?.value === 'youtube') {
      return {
        days: Math.round(budget / 100) * 7,
        impressions: budget * 300,
        views: budget * 100,
        interactions: budget * 2
      };
    } else if (this.formArray?.get([1, 'platform'])?.value === 'facebook' || this.formArray?.get([1, 'platform'])?.value === 'instagram') {
      return {
        days: Math.round(budget / 100) * 7,
        impressions: budget * 400,
        clicks: budget * 6.7,
        attendees: this.formArray?.get([0, 'type'])?.value === 'event' ? budget * 1.25 : 0
      };
    } else if (this.formArray?.get([1, 'platform'])?.value === 'spotify') {
      return {
        days: Math.round(budget / 250) * 30,
        listeners: budget * 3.5,
        impressions: budget * 260,
        streamings: budget * 13
      };
    } else if (this.formArray?.get([1, 'platform'])?.value === 'tiktok') {
      return {
        days: Math.round(budget / 20),
        impressions: budget * 1100,
        clicks: budget * 11,
        interactions: budget * 40
      };
    } else {
      return {
        days: Math.round(budget / 20),
        impressions: budget * 1100,
        clicks: budget * 13.5
      };
    }
  }

  createMetadataFromForm(formArray: AbstractControl | null) {
    const links: string[] = [];
    this.links.forEach((link) => {
      const linkValue = formArray?.get([2, link.formControlName])?.value;
      if (linkValue) {
        links.push(`${link.label}: ${linkValue}`);
      }
    });
    const countries: string[] = (formArray?.get([2, 'countries'])?.value as string).split(/\s+|,\s+/);
    const campaignMetadata: ICampaignMetadata = {
      type: formArray?.get([0, 'type'])?.value,
      platform: formArray?.get([1, 'platform'])?.value,
      links: JSON.stringify(links),
      countries: JSON.stringify(countries)
    };
    return campaignMetadata;
  }

  onCheckoutClicked() {
    if (this.demo || !this.currentUser || this.currentUser.role === UserRoleEnum.NONE) {
      this.openContactUsDemoDialog();
    } else {
      this.checkout();
    }
  }

  async checkout() {
    const budget = this.formArray?.get([3, 'budget'])?.value as number;
    const metadata = this.createMetadataFromForm(this.formArray);

    const campaign = {
      TTCBudget: budget,
      sessionId: null,
      phoneNumber: 'Abonnement',
      countries: JSON.parse(metadata?.countries as string),
      creationDate: new Date(),
      email: this.currentUser.email,
      name: 'Abonnement',
      links: JSON.parse(metadata?.links as string),
      platform: metadata?.platform as Platform,
      type: metadata?.type as CampaignType,
      notes: '',
      comments: this.formArray?.get([3, 'comments'])?.value || '',
    };
    this.subs.sink = this.successService
      .addCampaign(campaign)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          this.router.navigate(['./error'], { relativeTo: this.activatedRoute });
          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.subs.sink = this.successService.sendEmail().pipe(take(1)).subscribe();
        this.router.navigate(['./success'], { relativeTo: this.activatedRoute, state: { redirectTo: 'create-campaign' } });
      });
  }

  goNextStep() {
    setTimeout(() => this.stepper.next(), 500);
  }

  openContactUsDemoDialog(): void {
    this.dialog.open(CallToActionDialogComponent, {
      width: '250px',
      panelClass: 'call-to-action-dialog',
      data: {
        title: 'Vous avez aimé la démo ?',
        text: "N'attendez plus et contactez-nous pour demander votre accès et booster votre visibilité !",
        actionText: 'Nous contacter >>',
        actionUrl: 'https://mymusicads.com/nous-contacter'
      },
      scrollStrategy: new NoopScrollStrategy()
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
