import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IDocInfo, IQuestion } from '@shared';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnChanges {
  @Input() videoInfo: IDocInfo;
  @Input() isSelectedCourseLast: boolean;
  @Input() isBackOffice: boolean = false;
  @Input() mcq = [];
  @Output() goToNextCourse = new EventEmitter();

  videoUrl: SafeResourceUrl = '';

  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    let url = this.videoInfo.url;
    console.log("url = ", url);
    
    if (url.includes('youtube') || url.includes('youtu')) {
      if (url.includes('?')) {
        url += '&modestbranding=1&rel=0';
      } else {
        url += '?modestbranding=1&rel=0';
      }
    }

    console.log("url = ", url);
    
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}