import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { IUser, UserRoleEnum, categoriesMapping, IReference, ISession } from '@shared';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/firebase/firebase.service';
import { SubSink } from 'subsink';
import { DeleteAccountDialogComponent } from './delete-account-dialog/delete-account-dialog.component';
import { EditPictureDialogComponent } from './edit-picture-dialog/edit-picture-dialog.component';
import { FormArray } from '@angular/forms';
import { CalendlyService } from '../../services/calendly/calendly.service';
import { v4 as uuid } from 'uuid';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageProfileComponent implements OnInit {
  innerWidth = window.innerWidth;

  updateUserForm: FormGroup;
  changePasswordMessage = null;
  changePasswordError = false;
  saveProfileMessage = null;
  saveProfileError = false;

  isEnoughSubscriber: boolean = false;

  currentUser$: Observable<IUser> | null = null;
  currentUser: IUser | null = null;

  UserEnumRole = UserRoleEnum;

  photoUrl = '';

  redirectUrl: string;

  subs = new SubSink();

  coachingCategoriesOptions = [];
  constructor(
    private fb: FormBuilder,
    private firebaseService: FirebaseService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private calendlyService: CalendlyService,
    private afStorage: AngularFireStorage
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentUser$ = this.firebaseService.getCurrentUserProperties();
    this.subs.sink = this.currentUser$.subscribe((user) => {
      if (!user) return;
      this.photoUrl = user.photoUrl;
      this.currentUser = user;

      this.coachingCategoriesOptions = categoriesMapping[this.currentUser?.coachInfo?.categoryTitle];

      this.firebaseService.isEnoughRole(UserRoleEnum.SUBSCRIBER).then((res) => {
        this.isEnoughSubscriber = res;
      });

      this.updateUserForm = this.fb.group({
        id: [user.id],
        personalInfoForm: this.fb.group({
          email: [user.email],
          username: [user.displayName],
          firstName: [user.firstName],
          lastName: [user.lastName],
          photoUrl: [user.photoUrl]
        }),
        createCampaignInfoForm: this.fb.group({
          facebookUrl: [user.campaignInfo?.facebookUrl],
          youtubeEmail: [user.campaignInfo?.youtubeEmail],
          instaUrl: [user.campaignInfo?.instaUrl],
          googleAdsId: [user.campaignInfo?.googleAdsId],
          businessManagerId: [user.campaignInfo?.businessManagerId],
          defaultCountries: [user.campaignInfo?.defaultCountries]
        }),
        coachInfo: this.fb.group({
          coachingCategories: [[...(user.coachInfo?.coachingCategories || [])], Validators.required],
          description: this.fb.group({
            bulletsPoints: this.fb.array([]),
            content: [user.coachInfo?.description?.content || '', Validators.required],
            catchphrase: [user.coachInfo?.description?.catchphrase || '']
          }),
          catchphrasecard: [user.coachInfo?.catchphrasecard || '', Validators.required],
          references: this.fb.array([]),
          sessions: this.fb.array([])
        })
      });

      const sessionsData = user.coachInfo?.sessions || [];
      sessionsData.forEach((session) => {
        this.addSession(session);
      });

      const bulletsPointsData = user.coachInfo?.description?.bulletsPoints || [];
      bulletsPointsData.forEach((bulletPoint) => {
        this.addBulletPoint(bulletPoint);
      });

      const referencesData = user.coachInfo?.references || [];
      referencesData.forEach((reference) => {
        this.addReference(reference);
      });
    });

    this.route.queryParams.subscribe((params) => {
      this.redirectUrl = params['redirectUrl'] || 'home';
    });
  }

  formatDuration(duration: string): string {
    return this.calendlyService.formatDuration(parseInt(duration));
  }

  get sessions() {
    return this.updateUserForm.get('coachInfo').get('sessions') as FormArray;
  }

  get coachingCategories() {
    return this.updateUserForm.get('coachInfo').get('coachingCategories') as FormArray;
  }

  addSession(session: ISession) {
    console.log("addSession!!");
    const sessionGroup = this.fb.group({
      price: [session?.price] || '',
      duration: [session?.duration] || '',
      schedulingUrl: [session?.schedulingUrl] || ''
    });
    this.sessions.push(sessionGroup);
  }

  addCoachingCategory(category: string = '') {
    this.coachingCategories.push(this.fb.control(category));
  }

  get references() {
    return this.updateUserForm.get('coachInfo.references') as FormArray;
  }

  addReference(referenceContent?: IReference) {
    const referenceGroup = this.fb.group({
      link: [referenceContent?.link] || '',
      title: [referenceContent?.title] || '',
      description: [referenceContent?.description] || '',
      photoUrl: [referenceContent?.photoUrl] || ''
    });
    this.references.push(referenceGroup);
  }

  removeReference(index: number) {
    this.references.removeAt(index);
  }

  get bulletsPoints() {
    return this.updateUserForm.get('coachInfo.description.bulletsPoints') as FormArray;
  }

  addBulletPoint(bulletPointContent: string = '') {
    this.bulletsPoints.push(this.fb.control(bulletPointContent));
  }

  removeBulletPoint(index: number) {
    this.bulletsPoints.removeAt(index);
  }

  getDescriptionClass() {
    const description = this.updateUserForm.get('coachInfo').get('description').value;
    return description?.length ? 'textarea-label-visible' : 'textarea-label-hidden';
  }

  getCoachingCategoriesClass() {
    const coachingCategories = this.updateUserForm.get('coachInfo').get('coachingCategories').value;
    return coachingCategories?.length ? 'textarea-label-hidden' : 'textarea-label-visible';
  }

  openEditPictureDialog(): void {
    this.dialog.open(EditPictureDialogComponent, {
      width: '450px',
      panelClass: 'edit-picture-dialog',
      data: {
        user: this.currentUser
      }
    });
  }

  openDeleteAccountDialog(): void {
    this.dialog.open(DeleteAccountDialogComponent, {
      width: '450px',
      panelClass: 'delete-account-dialog'
    });
  }

  async updateUser(): Promise<void> {
    const userForm = this.updateUserForm.value;
    if (!this.updateUserForm.valid && this.currentUser.role === UserRoleEnum.COACH) {
      this.saveProfileMessage = 'Tous les champs obligatoires ne sont pas remplis';
      this.saveProfileError = true;
      throw 'All field must be completed';
    }
    this.firebaseService
      .updateUser({
        id: userForm.id,
        photoUrl: userForm.personalInfoForm.photoUrl,
        displayName: userForm.personalInfoForm.username,
        firstName: userForm.personalInfoForm.firstName,
        lastName: userForm.personalInfoForm.lastName,
        campaignInfo: {
          facebookUrl: userForm.createCampaignInfoForm.facebookUrl,
          youtubeEmail: userForm.createCampaignInfoForm.youtubeEmail,
          instaUrl: userForm.createCampaignInfoForm.instaUrl,
          googleAdsId: userForm.createCampaignInfoForm.googleAdsId,
          businessManagerId: userForm.createCampaignInfoForm.businessManagerId,
          defaultCountries: userForm.createCampaignInfoForm.defaultCountries
        },
        coachInfo: {
          ...this.currentUser.coachInfo,
          coachingCategories: userForm.coachInfo.coachingCategories,
          description: userForm.coachInfo.description,
          references: userForm.coachInfo.references,
          sessions: userForm.coachInfo.sessions,
          catchphrasecard: userForm.coachInfo.catchphrasecard
        },
        trainerId: this.currentUser.trainerId
      })
      .then(() => {
        this.saveProfileMessage = 'Profil sauvegardé !';
        this.saveProfileError = false;
      })
      .catch((err) => {
        this.saveProfileMessage = 'Une erreur est survenue';
        this.saveProfileError = true;
        throw err;
      });
    setTimeout(() => {
      this.saveProfileMessage = null;
    }, 10000);
  }

  async updateUserAndRedirect(): Promise<void> {
    this.updateUser().then(() => this.router.navigate([this.redirectUrl]));
  }

  uploadReferencePicture(event: Event, referenceIndex: number): void {
    const element = event.currentTarget as HTMLInputElement;
    let file = element.files ? element.files[0] : null;

    if (!file) return;

    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!acceptedImageTypes.includes(file.type)) {
      alert('Format de fichier non supporté.');
      return;
    }

    const filePath = '/referencePictures/' + uuid();
    this.afStorage
      .upload(filePath, file)
      .then((photo) => {
        photo.ref.getDownloadURL().then((photoUrl) => {
          (this.references.at(referenceIndex) as FormGroup).patchValue({ photoUrl });
        });
      })
      .catch((err) => {
        alert("Erreur lors du téléchargement de l'image");
        console.error(err);
      });
  }

  sendPasswordResetEmail(email: string): void {
    this.firebaseService
      .sendPasswordResetEmail(email)
      .then((message) => {
        this.changePasswordMessage = 'Un e-mail vous a été envoyé';
        this.changePasswordError = false;
      })
      .catch((err) => {
        this.changePasswordMessage = 'Une erreur est survenue';
        this.changePasswordError = true;
      });
    setTimeout(() => {
      this.changePasswordMessage = null;
    }, 10000);
  }
}
